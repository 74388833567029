<script setup lang="ts">
import { SpDialog } from '@dev.smartpricing/sp-vue-components'

const emit = defineEmits<{
	(e: 'update:modelValue', value: boolean): void
	(e: 'confirm'): void
}>()
const props = defineProps<{
	modelValue: boolean
}>()

const { isMobile } = useMobileViewport()

const dialogRef = ref<InstanceType<typeof SpDialog>>()

const isOpen = computed({
	get: () => props.modelValue,
	set: (value: boolean) => emit('update:modelValue', value),
})

const onModalClose = () => {
	dialogRef.value?.onClose()
}

const howDoesItWorkDialogHeader = computed(() => ({
	title: useLocale().translate(TranslationKeys['occupancy.how_does_it_work.TITLE']),
	subtitle: useLocale().translate(TranslationKeys['occupancy.how_does_it_work.SUBTITLE']),
	icon: SpCommonIcon.Box,
}))
</script>

<template>
	<ClientOnly>
		<SpDialog
			ref="dialogRef"
			v-model="isOpen"
			:headerTop="howDoesItWorkDialogHeader"
			:custom-options="{ dialogContainer: 'z-[999999]', dialogBodyContainer: 'flex flex-col space-y-4' }"
			:teleportTo="isMobile ? undefined : '#dialogs-container'"
			@close="onModalClose"
			:lock-body-scroll="false"
		>
			<SpText
				:size="SpTextSize.BodyMd02"
				class="text-secondary-gray-900"
				:text="useLocale().translate(TranslationKeys['occupancy.how_does_it_work.BODY_1'])"
			/>
			<SpText
				:size="SpTextSize.BodyMd02"
				class="text-secondary-gray-900"
				:text="useLocale().translate(TranslationKeys['occupancy.how_does_it_work.BODY_2'])"
			/>
			<div class="flex w-full flex-col space-y-2 rounded-xl bg-main-blue-50 px-3 py-[17px]">
				<SpText
					:size="SpTextSize.BodyMd03"
					class="text-main-blue-600"
					:text="useLocale().translate(TranslationKeys['occupancy.how_does_it_work.BODY_3_TITLE'])"
				/>
				<SpText
					:size="SpTextSize.BodyMd02"
					class="text-secondary-gray-900"
					:text="useLocale().translate(TranslationKeys['occupancy.how_does_it_work.BODY_3_BODY_1'])"
				/>
				<SpText
					:size="SpTextSize.BodyMd02"
					class="text-secondary-gray-900"
					:text="useLocale().translate(TranslationKeys['occupancy.how_does_it_work.BODY_3_BODY_2'])"
				/>
			</div>
			<template #footer>
				<div class="flex justify-end">
					<SpButton
						:size="SpButtonSize.Default"
						:type="SpButtonType.Primary"
						:text="useLocale().translate(TranslationKeys.CANCEL)"
						@click="onModalClose"
					/>
				</div>
			</template>
		</SpDialog>
	</ClientOnly>
</template>
